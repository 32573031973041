@use 'abstracts';
@use 'base' as *;
@use 'utilities' as *;
@use 'components' as *;
// @use '~@sick-davinci/theme-base/sass/theme';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import '~@angular/material/theming';
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat-core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $demo-primary: mat-palette($mat-blue);
// $demo-accent: mat-palette($mat-orange, A400);

// // The warn palette is optional (defaults to red).
// $demo-warn: mat-palette($mat-red);

// // Create the theme object (a Sass map containing all of the palettes).
// $demo-theme: mat-light-theme($demo-primary, $demo-accent, $demo-warn);

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($demo-theme);

/* You can add global styles to this file, and also import other style files */
// @import '../../../node_modules/@sick-davinci/theme-base/sass/theme.scss';
// $material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
// @import '~material-design-icons-iconfont/src/material-design-icons';
// @import '~@sick-curie/dashboard-builder/assets/dashboard-builder';

@import 'node_modules/@sick-curie/dashboard-builder/assets/dashboard-builder';

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Regular/open-sans-v17-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../../fonts/Regular/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Regular/open-sans-v17-latin-regular.woff2') format('woff2'),
    url('../../fonts/Regular/open-sans-v17-latin-regular.woff') format('woff'),
    url('../../fonts/Regular/open-sans-v17-latin-regular.ttf') format('truetype'),
    url('../../fonts/Regular/open-sans-v17-latin-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/SemiBold/open-sans-v17-latin-600.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-Semi-Bold'),
    url('../../fonts/SemiBold/open-sans-v17-latin-600.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/SemiBold/open-sans-v17-latin-600.woff2') format('woff2'),
    url('../../fonts/SemiBold/open-sans-v17-latin-600.woff') format('woff'),
    url('../../fonts/SemiBold/open-sans-v17-latin-600.ttf') format('truetype'),
    url('../../fonts/SemiBold/open-sans-v17-latin-600.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: bold;
  font-weight: 700;
  src: url('../../fonts/Bold/open-sans-v17-latin-700.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../../fonts/Bold/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Bold/open-sans-v17-latin-700.woff2') format('woff2'),
    url('../../fonts/Bold/open-sans-v17-latin-700.woff') format('woff'),
    url('../../fonts/Bold/open-sans-v17-latin-700.ttf') format('truetype'),
    url('../../fonts/Bold/open-sans-v17-latin-700.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

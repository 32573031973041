/*
  * Spacing
  */
$spacing: (
  'xxs': 0.25rem,
  'xs': 0.5rem,
  's': 1rem,
  'm': 1.25rem,
  'l': 1.75rem,
  'xl': 2.25rem,
  'xxl': 3rem,
  '3xl': 5rem,
  '4xl': 10rem,
);

// primary font
$primary_font: 'Lato';

// font-weight
$font-weight: (
  'thin': 100,
  'light': 300,
  'regular': 400,
  'bold': 700,
  'black': 900,
);

// font-size
$font-size: (
  'font-xs': 10px,
  'font-sm': 12px,
  'primary-fs': 14px,
  'font-md': 16px,
  'font-lg': 20px,
  'font-xl': 30px,
);

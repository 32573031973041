@use 'abstracts';

html,
body {
  height: 100%;
  font-size: map-get(abstracts.$font-size, 'primary-fs');
}

body {
  margin: 0;
  font-family: 'Open Sans', OpenSans, sans-serif;
  font-weight: map-get(abstracts.$font-weight, regular);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: map-get(abstracts.$colors, light-gray-text);
}

h1 {
  font-size: 2.488rem;
  font-weight: map-get(abstracts.$font-weight, regular);
}

h2 {
  font-size: 2.074rem;
  font-weight: map-get(abstracts.$font-weight, regular);
}

h3 {
  font-size: 1.728rem;
  font-weight: map-get(abstracts.$font-weight, regular);
}

h4 {
  font-size: 1.44rem;
  font-weight: 600;
}

h5 {
  font-size: 1.2rem;
  font-weight: map-get(abstracts.$font-weight, bold);
}

h6 {
  font-size: 1rem;
  font-weight: map-get(abstracts.$font-weight, bold);
}

p {
  color: map-get(abstracts.$colors, 'mid-gray-text');
}
